import React from "react";
import { isError, fieldLabel } from "../utils/errors";
import { fieldRun } from "../components/field-spec.js";

export const CrudForm = {
  form: (WrappedComponent, fieldValidations) => {
    return class extends React.Component {
      handleSubmit = (e) => {
        e.preventDefault();
        const fields = fieldRun(this.props.formModel, fieldValidations(this.props.formModel));
        const model = this.props.formModel;
        const newModel = Object.keys(fields).reduce((memo, key) => { return {...memo, [key]: {...memo[key], ...fields[key] } }; }, model);

        this.props.handleSubmit(newModel, fields);
      }

      isError = (field) => {
        return isError(field);
      }

      fieldLabel = (field) => {
        return fieldLabel(field);
      }

      handleChange = (_e, data) => {
        const { name, value } = data;

        const model = {
          ...this.props.formModel,
          [name]: {...this.props.formModel[name], value},
        };

        const fields = fieldRun(model, fieldValidations(model));

        const { valid, errorMessage } = fields[name] || {valid: true, errorMessage: ""};

        this.props.handleChange(
          {
            ...this.props.formModel,
            [name]: {...this.props.formModel[name], value, valid, errorMessage, editing: true},
          }
        )
      }

      handleBlur = (e, data) => {
        const { name, value } = data || e.target;

        const model = {
          ...this.props.formModel,
          [name]: {...this.props.formModel[name], value},
        };

        const fields = fieldRun(model, fieldValidations(model));

        const { valid, errorMessage } = fields[name] || {valid: true, errorMessage: ""};

        this.props.handleBlur(
          {
            ...this.props.formModel,
            [name]: {...this.props.formModel[name], value, valid, errorMessage, editing: false, touched: true},
          }
        )
      }

      render() {
        const { title, loading, formModel, ...anotherProps } = this.props;
        const { handleChange, handleBlur, handleSubmit, ...rest } = anotherProps;

        return (
          <WrappedComponent
            title={this.props.title}
            loading={this.props.loading}
            model={this.props.formModel}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            handleSubmit={this.handleSubmit}
            isError={this.isError}
            fieldLabel={this.fieldLabel}
            {...rest}
          />
        );
      }
    }
  }
}
