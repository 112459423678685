export const sessionManager = {
  addUser: (user, jwt) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("jwt", JSON.stringify(jwt));
  
    return null;  
  },
  getUser: () => {
    const user = sessionStorage.getItem("user") || null
    const jwt = sessionStorage.getItem("jwt") || null

    return {
      user: JSON.parse(user),
      jwt: JSON.parse(jwt),
    };  
  }
}
