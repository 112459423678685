export const status = (status) => {
  let s;
  switch (status) {
    case "pending":
      s = "Pendente";
      break;
  
    case "confirmed":
      s = "Confirmado";
      break;
    case "skipped":
      s = "Cancelado";
      break;  
    default:
      break;
  }

  return s;
}
