import {
  updateForm,
  fetchResource,
} from "../../actions/index";

export const AppointmentService = {
  updateStatus: (id, status) => {
    return updateForm("appointments", id, {appointment: {status: status}});
  },
  getAppointments: () => {
    return fetchResource("appointments");
  }
};
