import moment from "moment";
require('moment/locale/pt-br.js')

// const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale('pt-BR');

export function byDay(date) {
  return moment(date).startOf('day').format();
}
export function dateAppointment(date) {
  return moment(date).format('LL');
}

export function dateTimeAppointment(datetime) {
  return moment(datetime).format('LLL');
}

export function dateFormatter(date) {
  return moment(date).format("DD/MM/YYYY");
}

export function dateToUnix(date, time) {
  return moment.utc(date + " " + time).unix();
}

export function dateFromUnix(unixtime) {
  return moment.unix(unixtime).format("YYYY-MM-DD");
}

export function timeFromUnix(unixtime) {
  return moment.unix(unixtime).format("HH:mm");
}

export function unixToDate(unixtime) {
  return moment.unix(unixtime).toDate();
}

export function fromUnixTime(unixtime = 0) {
  return moment().unix() - unixtime;
}

export function isNextAppointment(date) {
  return moment(date).unix() >= moment().unix();
}
