import { fieldSpec } from "../../components/field-spec";
import {
  required,
  maxLength,
  phoneValidation,
} from "../../utils/rules";

export function fieldValidations(model) {
  return [
    fieldSpec(model.name, required, maxLength(140)),
    fieldSpec(model.phone, required, phoneValidation),
    fieldSpec(model.email, required, maxLength(140)),
    fieldSpec(model.password, required, maxLength(140)),
    fieldSpec(model.firstAppointment, required),
    fieldSpec(model.dateSlotId, required),
  ];
};
