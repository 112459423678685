import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import { addMessage } from "../reducers/message-slice";

export const MessageStatus = () => {
  const msg = useSelector(state => state.message)
  const dispatch = useDispatch()

  const handleDismiss = () => {
    dispatch(addMessage({text: "", active: false, error: false}))
  }

  return (
    <div style={{
      margin: "auto",
      maxWidth: "70%",
      position: "absolute",
      left: "0px",
      right: "0px",
      zIndex: 10000,
    }}>
      <Message floating onDismiss={handleDismiss} hidden={!msg.active} positive={!msg.error} negative={msg.error} >
        {msg.text}
      </Message>
    </div>
  );
}
