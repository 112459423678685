import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CrudForm } from "../../components/crud-form";
import { newSignupModel } from "./signup-model.js";
import { fieldValidations } from "./signup-spec";
import { signupService } from "./signup-service.js";
import { updateSession } from "../../reducers/session-slice.js";

import { SignupForm } from "./signup-form";
import { useNavigate } from "react-router-dom";
import { addMessage } from "../../reducers/message-slice";

const Form = CrudForm.form(SignupForm, fieldValidations)

export const SignupFormContainer = () => {
  const [loading, setLoading] = useState(true);
  const [doctorEmail] = useState("camilla.laurindo@gmail.com");
  const [model, setModel] = useState(newSignupModel());
  const [title, setTitle] = useState("");
  const [doctor, setDoctor] = useState({dates: [], id: null});
  const session = useSelector(state => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const cleanSession = () => {
      if (session.jwt !== null) {
        dispatch(updateSession({user: null, jwt: null}));  
      }

      return null;
    }

    const load = () => {
      signupService.findDoctor(doctorEmail).then(({ data }) => {
        setDoctor(data.data)
        setLoading(false)
      });
    }
  
    setTitle("Registrar")
    cleanSession();
    load();
  }, [dispatch, doctorEmail, session])

  const create = () => {
    return signupService.create(doctor.id, model);
  }

  const handleSubmit = (modelUpdated, fields) => {
    setModel(modelUpdated);

    if (Object.values(modelUpdated).some(el => el.valid === false)) {
      console.error(fields)
    } else {
      try {
        const req = create()
        req.then(({ data }) => {
          dispatch(updateSession(data.data));
          dispatch(addMessage({text: "Bem-Vindo! Aqui você pode conferir sua agenda de consultas.", active: true, error: false}))
          navigate("/appointments");
        })
        .catch((error) => {
          if (error.response) {
            dispatch(addMessage({text: error.response.data.errors, active: true, error: true}))
          } else {
            throw error;
          }
        })
        .finally(() => setLoading(false));
      } catch (error) {
        setLoading(false)
        dispatch(addMessage({text: "Problema inesperado. Por favor tente mais tarde.", active: true, error: true}))
        console.error(error);
      }
    }
  }

  const handleChange = (modelUpdated) => {
    setModel(modelUpdated)  }

  const handleBlur = (modelUpdated) => {
    setModel(modelUpdated)  }

  return (
    <Form
      title={title}
      loading={loading}
      formModel={model}
      doctor={doctor}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  );
}
