import React from "react";

export const errorFor = (state, field, label, model) => {
  if(state.showErrors || model.touched) {
    return state.validationErrors[field] || <label>{label}</label>;
  }
  return <label>{label}</label>;
};

export const isErrorFor = (state, field, model) => {
  if(state.showErrors || model.touched) {
    return typeof(state.validationErrors[field]) === "string";
  }
  return false;
};

export const fieldLabel = (field) => {
  return <label>{field.errorMessage || field.label}</label>;
};

export const isError = (field) => {
  return !field.valid;
};
