import { createSlice } from '@reduxjs/toolkit'

export const messageSlice = createSlice({
  name: "message",
  initialState: {text: "", active: false, error: false},
  reducers: {
    addMessage: (state, {payload}) => {
      return {
        ...state,
        text: payload.text,
        active: payload.active,
        error: payload.error,
      }
    }
  }
})

export const { addMessage } = messageSlice.actions

export default messageSlice.reducer
