import reportWebVitals from './reportWebVitals';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import { App } from './App';
import { Login } from './views/login/login';
import { Camilla } from './views/home-pages/camilla';
import { SignupFormContainer } from './views/signup/signup-form-container';
import { PrivateRoute } from './components/private_route';
import { AppointmentList } from './views/appointment/appointment-list.jsx';
import { PatientList } from './views/patients/patient-list.jsx';
import store from './reducers/store';

const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Camilla />,
  },
  {
    path: "sign_in",
    element: <Login />,
  },
  {
    path: "sign_up",
    element: <SignupFormContainer />,
  },
  {
    path: "appointments",
    element: <App><PrivateRoute element={AppointmentList} /></App>,
  },
  {
    path: "patients",
    element: <App><PrivateRoute element={PatientList} /></App>,
  },
]);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
