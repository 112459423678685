import Big from "big.js";
import * as ErrorMessages from "../lib/error-messages.js";
import { dateParser } from "../lib/date-parser";
import { cepParser } from "../lib/cep-parser";
import { timeParser } from "../lib/time-parser.js";
import { phoneParser } from "../lib/phone-parser.js";
import { cpfParser } from "../lib/cpf-parser.js";

export const required = (text) => {
  if (text) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
};

export const isNumber = (text) => {
  if (Number(text) >= 0) {
    return null;
  } else {
    return ErrorMessages.isNotANumber;
  }
};

export const lessThanTotal = (text, state) => {
  if (text !== "") {
    const [d, t] = [Big(text), Big(state.total.value)];

    if (d.gt(0) && t.gt(0) && d.gte(t)) {
      return ErrorMessages.lessThanTotal;
    } else {
      return null;
    }
  }

  return null;
};

export const dateValidation = (text) => {
  const { valid } = dateParser(text);

  if (valid) {
    return null;
  } else {
    return ErrorMessages.dateInvalid;
  }
};

export const timeValidation = (text) => {
  const { valid } = timeParser(text);

  if (valid) {
    return null;
  } else {
    return ErrorMessages.timeInvalid;
  }
};

export const cepValidation = (text) => {
  const { valid } = cepParser(text);
  if (valid) {
    return null;
  } else {
    return ErrorMessages.cepInvalid;
  }
};

export const cnpjRaizValidation = (text) => {
  const match = text.match(/^\s*(\d{2})\.?(\d{3})\.?(\d{3})\s*/);
  return match ? null : ErrorMessages.cnpjInvalid;
};

export const cpfValidation = (value) => {
  const valid = cpfParser(value);

  if (valid) {
    return null;
  } else {
    return ErrorMessages.cpfInvalid;
  }
};

export function phoneValidation(text) {
  const { valid } = phoneParser(text);
  if (valid) {
    return null;
  } else {
    return ErrorMessages.phoneInvalid;
  }
}

export const placaValidation = (text) => {
  const matchOld = text.match(/[a-z]{3}-?\d{4}/i);
  const matchNew = text.match(/[A-Z]{3}[0-9][A-Z][0-9]{2}/i);
  return matchOld || matchNew ? null : ErrorMessages.placaInvalid;
};

export const mustMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] === text ? null : ErrorMessages.mustMatch(fieldName);
  };
};

export const minLength = (length) => {
  return (text) => {
    if (text) {
      return text.length >= length ? null : ErrorMessages.minLength(length);
    } else {
      return null;
    }
  };
};

export const maxLength = (length) => {
  return (text) => {
    if (text) {
      return text.length > length ? ErrorMessages.maxLength(length) : null;
    } else {
      return null;
    }
  };
};

export const reportsValidation = (_field, state) => {
  if ([state.general.value, state.by_vehicle.value].includes(true)) {
    return null;
  } else {
    return ErrorMessages.reportInvalid;
  }
};

export const repeatedItem = (value, index, items) => {
  const values = items.map(item => item.model.name.value);
  const itemRepeated = Object.assign([], values, {[index]: value}).filter(el => el === value).length > 1;

  const {v, message} = {v: !itemRepeated, message: itemRepeated ? "Item já informado" : "" };

  return {valid: v, errorMessage: message};
};
