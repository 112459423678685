import React, { useState } from "react";
import { Form, Header, Item, Segment } from "semantic-ui-react";
import { dateTimeAppointment } from "../utils/date";

export const AppointmentDateTimeInput = (props) => {
  const {
    doctor,
    firstAppointmentField,
    dateSlotField,
    handleBlur,
    onChange,
    isError,
  } = props;

  const [wdays] = useState({1: "Seg", 2: "Ter",3: "Qua", 4: "Qui", 5: "Sex", 6: "Sab", 7: "Dom"});
  const [selectedFirstAppointment, setFirstAppointment] = useState("");
  const [selectedDateSlot, setDateSlot] = useState("");
  const [selectedDateSlotLabel, setDateSlotLabel] = useState("");

  const handleChangeWday = (e, data) => {
    const firstDate = doctor.dates.find(d => d.id === data.id);
    const firstSlot = firstDate.slots[0];

    setFirstAppointment(data.value);
    setDateSlot(firstSlot.dateSlotId);
    setDateSlotLabel(firstSlot.slot);
    onChange(e, { name: firstAppointmentField.id, value: data.value});
    onChange(e, { name: dateSlotField.id, value: firstSlot.dateSlotId});
  }

  const handleChangeSlot = (e, {value, label}) => {
    setDateSlot(value);
    setDateSlotLabel(label);
    onChange(e, { name: dateSlotField.id, value: value});
  }

  const handleBlurWday = (e, _data) => {
    handleBlur(e, { name: firstAppointmentField.id, value: selectedFirstAppointment});
  }

  const handleBlurChangeSlot = (e, _data) => {
    handleBlur(e, { name: dateSlotField.id, value: selectedDateSlot});
  }

  const renderResume = (isDT) => {
    return(
      <Item.Group style={{minHeight: "10em"}}>
        <Item>
          <Item.Content verticalAlign='middle'>
            <Item.Header>
              Consulta Online
            </Item.Header>
            <Item.Meta>
              <p>Psicóloga: Camilla Laurindo</p>
              <p>
                Data/Hora: {isDT && dateTimeAppointment(`${selectedFirstAppointment} ${selectedDateSlotLabel}`)}
              </p>
            </Item.Meta>
          </Item.Content>
        </Item>
      </Item.Group>
    )
  }

  return(
    <>
      <Segment style={{paddingBottom: "1em", minHeight: "15em"}}>
        <Header as="h5">Dia/Horário</Header>
        <Form.Group inline>
          {doctor.dates.map(date => {
            if (date.slots.some(a => a)) {
              return (
                <div key={date.id}>
                  <Form.Radio
                    id={date.id}
                    name={firstAppointmentField.label}
                    value={date.firstAppointment}
                    label={wdays[date.wday]}
                    checked={selectedFirstAppointment === date.firstAppointment}
                    onChange={handleChangeWday}
                    onBlur={handleBlurWday}
                    error={isError(firstAppointmentField)}
                  />
                </div>
              )
            } else {
              return null
            }
          })}
        </Form.Group>
        {selectedFirstAppointment !== "" && doctor.dates.find(date => date.firstAppointment === selectedFirstAppointment).slots.map(slot => {
          return (
            <div key={slot.dateSlotId}>
              <Form.Radio
                id={slot.dateSlotId}
                value={slot.dateSlotId}
                label={slot.slot}
                name={dateSlotField.name}
                checked={selectedDateSlot === slot.dateSlotId}
                onChange={handleChangeSlot}
                onBlur={handleBlurChangeSlot}
                error={isError(dateSlotField)}
              />
            </div>
          )
        })}
      </Segment>
      <Segment>
        {renderResume(selectedFirstAppointment && selectedDateSlotLabel)}
      </Segment>
    </>
  );
}
