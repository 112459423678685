import { FieldModel } from "../../components/field-model";

export function newSignupModel() {
  return {
    name: FieldModel("name", "Nome"),
    phone: FieldModel("phone", "Telefone"),
    email: FieldModel("email", "Email"),
    password: FieldModel("password", "Senha"),
    firstAppointment: FieldModel("firstAppointment", "Data da Primeira Sessão"),
    dateSlotId: FieldModel("dateSlotId", "Horário"),
  };
};
