import React, { useCallback, useMemo, useState } from "react";
import { Button, Divider, Icon, Modal } from "semantic-ui-react";
import { Editor, createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { AnamnesisService } from "./anamnesis-service";

export const Anamnesis = ({patientId, patientName}) => {
  const [open, setOpen] = useState(false)
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState([
    {
      type: 'paragraph',
      children: [
        {
          text: 'Adicione aqui a anamnese do paciente...',
        },
      ],
    },
  ]);

  const renderElement = useCallback(
    ({ attributes, children }) => <p {...attributes}>{children}</p>,
    []
  )

  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const handleFormatClick = (format) => {
    toggleFormat(editor, format);
  };

  const upsertAnamnesis = () => {
    AnamnesisService.upsert(patientId, value).then(({ _data }) => {
      setOpen(false);
    });
  }

  const load = () => {
    AnamnesisService.getAnamnesis(patientId).then(({ data }) => {
      if(data.data !== "" && data.data !== null) {
        setValue(data.data.description.content);
        editor.children = data.data.description.content;
      }
    });
  }

  const handleOpen = () => {
    load();
    setOpen(true);
  }

  const handleValue = (value) => {
    setValue(value);
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => handleOpen()}
      open={open}
      trigger={<Button>Editar Anamnesis</Button>}
    >
      <Modal.Header>Anamnesis - {patientName}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div>
            <Toolbar handleFormatClick={handleFormatClick} />
            <Slate editor={editor} value={value} onChange={handleValue}>
              <Editable renderElement={renderElement} renderLeaf={renderLeaf} />
            </Slate>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Canceler
        </Button>
        <Button
          content="Salvar"
          labelPosition='right'
          icon='checkmark'
          onClick={upsertAnamnesis}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const toggleFormat = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Toolbar = ({ handleFormatClick }) => {
  return (
    <>
      <div>
        <span><Icon onClick={() => handleFormatClick('bold')} name="bold" /></span>
        <span><Icon onClick={() => handleFormatClick('italic')} name="italic" /></span>
        <span><Icon onClick={() => handleFormatClick('underline')} name="underline" /></span>
      </div>
      <Divider />
    </>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}
