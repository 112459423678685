export const isRequired = fieldName => `${fieldName} é obrigatório`;

export const dateInvalid = fieldName => `${fieldName} é inválido`;

export const timeInvalid = fieldName => `${fieldName} é inválido`;

export const cepInvalid = fieldName => `${fieldName} é inválido`;

export const cnpjInvalid = fieldName => `${fieldName} é inválido`;

export const cpfInvalid = fieldName => `${fieldName} é inválido`;

export const phoneInvalid = fieldName => `${fieldName} é inválido`;

export const placaInvalid = fieldName => `${fieldName} é inválido`;

export const mustMatch = otherFieldName => {
  return (fieldName) => `${fieldName} deve corresponder ${otherFieldName}`;
};

export const minLength = length => {
  return (fieldName) => `${fieldName} deve ter pelo menos ${length} caracteres`;
};

export const maxLength = length => {
  return (fieldName) => `${fieldName} deve ter no máximo ${length} caracteres`;
};

export const isNotANumber = fieldName => `${fieldName} deve um número positivo, use ponto "." em vez de virgula ","`;

export const lessThanTotal = fieldName => `${fieldName} deve ser menor que Total`;

export const reportInvalid = () => "Ao menos um relatório deve ser selecionado";
