import { createSlice } from '@reduxjs/toolkit'
import { sessionManager } from './session-manager';

export const sessionSlice = createSlice({
  name: "session",
  initialState: {...sessionManager.getUser()},
  reducers: {
    updateSession: (state, {payload}) => {
      sessionManager.addUser(
        payload.user,
        payload.jwt,
      );

      return {
        ...state,
        user: payload.user,
        jwt: payload.jwt,
      }
    }
  }
})

export const { updateSession } = sessionSlice.actions

export default sessionSlice.reducer
