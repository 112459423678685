import { fetchResource, saveForm } from "../actions";

export const AnamnesisService = {
  upsert: (patientId, description) => {
    const form = {
      description: description,
    }
    console.log(form);
    return saveForm("anamnesis", {patient_id: patientId, ...form});
  },
  getAnamnesis: (patientId) => {
    return fetchResource(`anamnesis?patient_id=${patientId}`);
  }
};
