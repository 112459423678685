import React from "react";
import { Button, Menu } from "semantic-ui-react";

const extraItem = () => (
  <Menu.Item position='right'>
    <Button as='a' href="/sign_in">
      Login
    </Button>
    <Button as='a' primary={true} style={{ marginLeft: '0.5em' }} href="/sign_up">
      Registrar
    </Button>
  </Menu.Item>
)

const logoutItem = () => (
  <Menu.Item position='right'>
    <Button as='a' href="/sign_in">
      Logout
    </Button>
  </Menu.Item>
)

export const MyHeader = (props) => (
  <Menu fixed='top' color="teal" inverted>
    <Menu.Menu style={{marginLeft: "0.5em"}}>
      {props.items.map((item, i) => {
        return (
          <Menu.Item key={i} as='a' href={item.href} active={item.active}>
            {item.description}
          </Menu.Item>
        )
      })}
    </Menu.Menu>
    {(props.extra && extraItem()) || (props.logout && logoutItem())}
  </Menu>
)
