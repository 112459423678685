import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { run, ruleRunner } from "../../utils/rule-runner.js";
import { required } from "../../utils/rules.js";
import { Button, Form, Grid, Header, Message, Segment, Loader, Dimmer } from "semantic-ui-react";
import { loginService } from "./login-service.js";
import { updateSession } from "../../reducers/session-slice.js";
import { MyHeader } from "../../components/my-header.jsx";
import { MyFooter } from "../../components/my-footer.jsx";
import { useNavigate } from "react-router-dom";
import { addMessage } from "../../reducers/message-slice.js";

const menuItems = [
  {
    description: "Home",
    href: "/",
  },
]

export const Login = () => {
  const [showErrors, setShowErrors] = useState(false)
  const [validationErrors, validateErros] = useState({})
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState(
    {
      username: { value: "", touched: false },
      password: { value: "", touched: false },
    }
  )
  const dispatch = useDispatch()
  const message = useSelector(state => state.message)
  const session = useSelector(state => state.session);

  const fieldValidations = [
    ruleRunner("username", "E-mail", required),
    ruleRunner("password", "Senha", required),
  ];

  useEffect(() => {
    const cleanSession = () => {
      if (session.jwt !== null) {
        dispatch(updateSession({user: null, jwt: null}));
      }

      return null;
    }

    cleanSession();
  }, [dispatch, session])

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    setShowErrors(true)
    validateErros(run(model, fieldValidations))

    if (Object.keys(validationErrors).length > 0) {
      console.error(validationErrors);
    } else {
      try {
        setLoading(true)

        loginService.signIn(model).then(({ data }) => {
          dispatch(updateSession(data.data));
          dispatch(addMessage({text: "Bem-Vindo! Aqui você pode conferir sua agenda de consultas.", active: true, error: false}))
          navigate("/appointments");
        })
        .catch((error) => {
          if (error.response) {
            dispatch(addMessage({text: "Confira seu Email ou Senha.", active: true, error: true}))
          } else {
            throw error;
          }
        })
        .finally(() => setLoading(false));
      } catch (error) {
        setLoading(false)
        dispatch(addMessage({text: "Problema inesperado. Por favor tente mais tarde.", active: true, error: true}))
        console.error(error);
      }
    }
  }

  const errorFor = (field, model) => {
    if (showErrors || model.touched) {
      return validationErrors[field] || null;
    }
    return null;
  }

  const handleChange = (_e, data) => {
    const name = data.name;
    const value = data.value;
    setModel({...model, [name]: {...model[name], value: value}})
    validateErros(run(model, fieldValidations))
  }

  const validate = (e) => {
    const name = e.target.name;

    setModel({...model, [name]: {...model[name], touched: true}})
    validateErros(run(model, fieldValidations))
  }

  return (
    <>
      <header><MyHeader items={menuItems} extra /></header>
      <div className="main">
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <Grid
          verticalAlign='middle'
          textAlign='center'
          stackable
        >
          <Grid.Column width={6}>
            <Header as='h2'>
              Agendamento de Consultas
            </Header>
            <Form size='large' onSubmit={onSubmit}>
              <Message negative hidden={!message.active} >
                <b>{message.text}</b>
              </Message>
              <Segment stacked textAlign='left'>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  value={model.username.value}
                  type="email"
                  name="username"
                  placeholder="E-mail"
                  onChange={handleChange}
                  onBlur={validate}
                  error={errorFor("username", model.username) !== null}
                  label={errorFor("username", model.username)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  value={model.password.value}
                  type="password"
                  name="password"
                  placeholder="Senha"
                  onChange={handleChange}
                  onBlur={validate}
                  error={errorFor("password", model.password) !== null}
                  label={errorFor("password", model.password)}
                />
                <Button color="teal" fluid size="large">Login</Button>
              </Segment>
            </Form>
            <Message>
              Novo por aqui? <a href="/sign_up">Sign Up</a>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
      <footer className="footer"><MyFooter /></footer>
    </>
  );
}
