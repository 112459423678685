export const FieldModel = (id, label) => {
  return {
    id: id,
    label: label,
    value: "",
    touched: false,
    editing: false,
    valid: true,
    errorMessage: "",
  };
};

export const FieldModelArr = (id, label) => {
  return {
    id: id,
    label: label,
    value: [],
    touched: false,
    editing: false,
    valid: true,
    errorMessage: "",
  };
};
