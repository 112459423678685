import React from "react";
import { Container, Grid, Header, Icon, List, Segment } from "semantic-ui-react";

export const MyFooter = () => (
  <Segment style={{ padding: '1em 0em' }} vertical>
      <Container textAlign='center'>
        <Grid divided inverted stackable centered>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Horários de Consulta' />
            <List link inverted>
              <List.Item>Seg - Sex: 08:00 - 17:00</List.Item>
              <List.Item>Psicóloga | Neuropsicóloga</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Contato' />
            <List link inverted>
              <List.Item>camilla.laurindo@gmail.com</List.Item>
              <List.Item>CRP: 11/15958</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Social' />
            <List link inverted>
             <List.Item inverted>
               <a href='https://www.instagram.com/millalaurindo/'>Instagram</a>
             </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Container>
  </Segment>
)
