import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from './session-slice'
import messageReducer from './message-slice'

export default configureStore({
  reducer: {
    session: sessionReducer,
    message: messageReducer,
  }
})
