import React from 'react';
import "./App.scss";
import { MessageStatus } from './components/message-status';
import { MyFooter } from './components/my-footer';
import { MyHeader } from './components/my-header';
import { useSelector } from 'react-redux';

export const App = (props) => {  
  const session = useSelector(state => state.session);
  const menuItems = [
    {
      description: "Consultas",
      href: "/appointments",
    },
  ]
  
  const adminMenuItems = [
    {
      description: "Pacientes",
      href: "/patients",
    },
  ]
  
  const allMenuItems = () => {
    if (session.user && session.user.admin) {
      return [...menuItems, ...adminMenuItems];
    } else {
      return menuItems;
    }
  }
  
  return (
    <>
      <header>
        <MyHeader items={allMenuItems()} logout />
      </header>
      <MessageStatus />
      <main className="main">
        {props.children}
      </main>
      <footer className="footer"><MyFooter /></footer>
    </>
  );
}
