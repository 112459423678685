export const fieldSpec = (field, ...validations) => {
  return (model) => {
    const errors = validations.map(validation => validation(model[field.id].value, model)).filter(e => e);

    if (errors[0]) {
      return { [field.id]: {valid: false, errorMessage: errors[0](field.label)} };
    } else {
      return { [field.id]: {valid: true, errorMessage: ""} };
    }
  };
};

export const fieldRun = (model, runners) => {
  return runners.reduce((memo, runner) => {
    return Object.assign(memo, runner(model));
  }, {});
};
