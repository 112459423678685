import {
  saveForm,
  fetchResource,
} from "../../actions/index";

export const signupService = {
  create: (doctorId, model) => {
    const formModel = {
      user: {
        email: model.email.value,
        password: model.password.value,
      },
      patient: {
        name: model.name.value,
        phone: model.phone.value,
      },
      contract: {
        date_slot_id: model.dateSlotId.value,
      },
      first_appointment: {
        appointment_date: model.firstAppointment.value,
        date_slot_id: model.dateSlotId.value,
        doctor_id: doctorId,
      },
    };
    return saveForm("funnil/sign_up", formModel);
  },
  findDoctor: (id) => {
    return fetchResource(`funnil/doctor_availabily?doctor_email=${id}`);
  },
};
