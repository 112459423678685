import React, { useState } from "react";
// import logo from "../images/camilla_logo.png";
import camilla from "../images/camilla.jpeg";
import psic from "../images/psic.jpg";
import neuro from "../images/neuro.png";
import child from "../images/child.jpg";
import problem from "../images/problem.jpg";
import teen from "../images/teen.jpg";
import smart from "../images/smart.jpg";
import { Button, Grid, Icon, Image, Segment, Header, Card, Modal, Accordion, Container } from "semantic-ui-react";
import "../../App.scss";
import "semantic-ui-css/semantic.min.css";
import { MyFooter } from "../../components/my-footer";
import { MyHeader } from "../../components/my-header";
import { Content } from "./content";

export const Camilla = () => {
  const menuItems = [
    {
      description: "Home",
      href: "/",
    },
  ]

  const HeroSpace = () => {
    return (
      <div className="hero-container">
        <Segment vertical textAlign="center" className="hero-segment">
          <Header as="h1" style={{color: 'rgb(239 162 130)', fontSize: '2em', }} content="BEM VINDO(A)" />
          <Header as="h3" style={{fontWeight: 500, color: '#fff', fontSize: '2em'}} content="Guiando você em direção a um amanhã mais brilhante." />
          <Button primary size='huge' as="a" href="/sign_up">
            Agende uma Consulta
            <Icon name='right arrow' />
          </Button>

        </Segment>
      </div>
    );
  };

  return (
    <>
      <header><MyHeader items={menuItems} extra /></header> 
      <main className="main">
        <HeroSpace />
        <MyMain />
      </main>
      <footer className="footer"><MyFooter /></footer>
    </>
  );
}

const MyMain = () => {
  const [content, setContent] = useState("")
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const selectContent = (content) => {
    setContent(content);
    setOpen(true);
  }

  const handleClick = (index) => {
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  return (
    <div>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        centered
        size="large"
      >
        <Content content={content} />
        <Modal.Actions>
          <Button color='black' onClick={() => setOpen(false)}>
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment style={{ padding: '5em 1em' }} vertical>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={8}>
              <div className="presentation">
                <Header as='h3' style={{ fontSize: '1.5em' }}>
                  Queridos visitantes e pacientes!
                </Header>
                <p style={{ fontSize: '1em' }}>
                  Bem vindo a clinica de família Camilla Laurindo.
                </p>
                <Header as='h6'>CRP: 11/15958</Header>
                <Header as='h3' style={{ fontSize: '1.5em' }}>
                  Neste espaco, gostaria de me apresentar.
                </Header>
                <p>
                  Olá! Sou Camilla Laurindo, psicóloga com uma paixão genuína por ajudar as pessoas a enfrentar desafios e descobrir o seu potencial emocional. 
                </p>
                <p>
                  Acredito que todos podemos crescer e alcançar uma vida mais plena e satisfatória. 
                  Minha abordagem terapêutica é baseada na empatia, acolhimento e respeito, oferecendo um espaço seguro para que você possa se expressar livremente 
                  e explorar suas questões mais profundas. Seja para lidar com ansiedade, estresse, relacionamentos ou qualquer outra dificuldade, 
                  estou aqui para caminhar ao seu lado nesta jornada de autodescoberta e crescimento pessoal.
                </p>
                <p>
                  Vamos trabalhar juntos rumo ao seu bem-estar emocional e qualidade de vida. Agende uma consulta e dê o primeiro passo para uma mudança positiva em sua vida. Estou ansiosa para conhecê-lo(a)!
                </p>
                <div style={{fontFamily: 'Best Signature Font', fontSize: '1.5em'}}>Camilla Laurindo</div>
              </div>
            </Grid.Column>
            <Grid.Column floated='right' width={6}>
              <Image circular size='large' src={camilla} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Button primary size='huge' as="a" href="/sign_up">
                Agende uma Consulta
                <Icon name='right arrow' />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment style={{ padding: '2em 1em' }} vertical>
        <Header as="h2" style={{ textAlign: 'center', padding: '1em 1em' }}>Como eu posso ajudar</Header>
        <Grid divided inverted stackable verticalAlign='middle'>
          <Card.Group stackable itemsPerRow={3}>
          <Card>
            <Image src={psic} wrapped />
            <Card.Content>
              <Card.Header>Psicoterapia Individual</Card.Header>
              <Card.Description>
                Trata-se de um método profundo baseado na fala, que busca elementos inconscientes significantes e auxilia na tomada de consciência, fazendo com que as pessoas tenham maior conhecimento sobre elas mesmas e tenham poder sobre suas próprias vidas e escolhas
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Image src={child} wrapped />
            <Card.Content>
              <Card.Header>Psicoterapia Infantil</Card.Header>
              <Card.Description>
                Tem a finalidade de melhorar a qualidade de vida da criança, proporcionando uma infância feliz e saudável.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Image src={neuro} wrapped />
            <Card.Content>
              <Card.Header>Avaliação Neuropsicológica</Card.Header>
              <Card.Description>
                A avaliação neuropsicológica é um exame que tem como objetivo investigar os aspectos cognitivos, emocionais e comportamentais do paciente.
              </Card.Description>
            </Card.Content>
          </Card>
          </Card.Group>
        </Grid>
      </Segment>
      <Segment style={{ padding: '2em 1em' }} vertical>
        <Header as="h2" style={{ textAlign: 'center', padding: '1em 1em' }}>Artigos e Pensamentos</Header>
        <Grid divided inverted stackable verticalAlign='middle'>
          <Card.Group stackable itemsPerRow={3}>
            <Card link onClick={() => selectContent("psico")}>
              <Image src={problem} wrapped />
              <Card.Content>
                <Card.Header>Porque se deve procurar ajuda psicológica no início dos problemas?</Card.Header>
              </Card.Content>
            </Card>
            <Card link onClick={() => selectContent("child")}>
              <Image src={teen} wrapped />
              <Card.Content>
                <Card.Header>Sintomas e sinais de alarme de problemas psicológicos na infância e na adolescência</Card.Header>
              </Card.Content>
            </Card>
            <Card link onClick={() => selectContent("neuro")}>
              <Image src={smart} wrapped />
              <Card.Content>
                <Card.Header>Para que serve a neuropsicologia?</Card.Header>
              </Card.Content>
            </Card>
          </Card.Group>
        </Grid>
      </Segment>
      <Segment style={{ padding: '2em 1em' }} vertical>
        <Container>
        <div className='presentation'>
          <Header as="h2" style={{ textAlign: 'center' }}>Principais Dúvidas</Header>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={() => handleClick(0)}
            >
              <Icon name='dropdown' />
              Como funciona o atendimento?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
              O atendimento é realizado de forma presencial em meu consultório, proporcionando um ambiente acolhedor e privado para discutirmos suas questões com segurança. 
              Também oferecemos atendimentos online para aqueles que preferem a comodidade e flexibilidade de sessões remotas.
              </p>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={() => handleClick(1)}
            >
              <Icon name='dropdown' />
              Quantas sessões são necessárias?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                A quantidade de sessões pode variar de acordo com suas necessidades e objetivos terapêuticos. Alguns clientes alcançam resultados significativos em poucas sessões, enquanto outros podem preferir um acompanhamento mais prolongado. Nossa abordagem é flexível e adaptada às suas necessidades individuais.
              </p>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={() => handleClick(2)}
            >
              <Icon name='dropdown' />
              Quais questões posso abordar nas sessões?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>
                Você pode abordar qualquer questão ou desafio que esteja enfrentando em sua vida. Isso inclui, mas não se limita a, ansiedade, estresse, problemas de relacionamento, questões familiares, luto, transições de vida e questões de autoestima. Estou aqui para oferecer apoio e orientação em diversas áreas.
              </p>
            </Accordion.Content>
          </Accordion>
        </div>
        </Container>
      </Segment>
    </div>
  )
}