import React from "react";
import { Button, Form, Header, Segment, Container, Grid, Image, Card } from "semantic-ui-react";
import { AppointmentDateTimeInput } from "../../components/appointment-date-time-input";
import { MyFooter } from "../../components/my-footer";
import { MyHeader } from "../../components/my-header";
import camillaProfile from "../images/camilla_profile.jpeg";

const menuItems = [
  {
    description: "Home",
    href: "/",
  },
]

export const SignupForm = (props) => {
  const {
    title,
    loading,
    handleSubmit,
    model,
    handleChange,
    handleBlur,
    isError,
    fieldLabel,
    doctor,
  } = props;

  return (
    <>
      <header><MyHeader items={menuItems} extra /></header>
      <main className="main">
        <Container>
          <Header style={{ textAlign: "center" }} as="h1">Agendamento de Consultas</Header>
          <Grid
            centered
            stackable
          >
            <Grid.Column style={{ width: "auto" }}>
              <Card fluid>
                <Image src={camillaProfile} />
                <Card.Content>
                  <Card.Header>Camilla Laurindo</Card.Header>
                  <Card.Meta>
                    <p>
                      <span>Psicóloga | Neuropsicóloga</span>
                    </p>
                    <p>
                      <span>CRP: 11/15958</span>
                    </p>
                  </Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={6}>
              <Header>{title}</Header>
              <Form loading={loading} size='large' onSubmit={handleSubmit}>
                <Segment>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    value={model.name.value}
                    type="text"
                    name={model.name.id}
                    placeholder={model.name.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isError(model.name)}
                    label={fieldLabel(model.name)}
                  />
                  <Form.Input
                    fluid
                    icon="phone"
                    iconPosition="left"
                    value={model.phone.value}
                    type="phone"
                    name={model.phone.id}
                    placeholder={model.phone.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isError(model.phone)}
                    label={fieldLabel(model.phone)}
                  />
                  <Form.Input
                    fluid
                    icon="mail"
                    iconPosition="left"
                    value={model.email.value}
                    type="email"
                    name={model.email.id}
                    placeholder={model.email.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isError(model.email)}
                    label={fieldLabel(model.email)}
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    value={model.password.value}
                    type="password"
                    name={model.password.id}
                    placeholder={model.password.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isError(model.password)}
                    label={fieldLabel(model.password)}
                  />
                  <AppointmentDateTimeInput
                    doctor={doctor}
                    firstAppointmentField={model.firstAppointment}
                    dateSlotField={model.dateSlotId}
                    isError={isError}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <Button
                    content={title}
                    labelPosition="left"
                    icon="checkmark"
                    positive
                  />
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
        </Container>
      </main>
      <footer className="footer"><MyFooter /></footer>
    </>
  );
}
