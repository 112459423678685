import { server } from "../utils/server";

export function fetchResource(resourse) {
  return server().get(`/${resourse}`);
}

export function findResource(resourse, id) {
  return server().get(`/${resourse}/${id}`);
}

export function fetchLookup(resourse, text) {
  return server().post(`/${resourse}`, { text: text });
}

export function saveForm(resourse, form) {
  return server().post(`/${resourse}`, form);
}

export function updateForm(resourse, id, form) {
  return server().put(`/${resourse}/${id}`, form);
}

export function deleteResource(resourse, id) {
  return server().delete(`/${resourse}/${id}`);
}
