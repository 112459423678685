import React, { useEffect, useState } from "react";
import { Container, Dimmer, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { PatientService } from "./patient-service";
import { Anamnesis } from "../../components/anamnesis";

export const PatientList = () => {
  const [loading, setLoading] = useState(true)
  const [patients, setPaitients] = useState([])

  useEffect(() => {
    const load = () => {
      PatientService.getPatients().then(({ data }) => {
        setPaitients(data.data.patients);
        setLoading(false)
      });
    }
    load();
  }, []);

  const renderPatients = () => {
    return patients.map((patient) => {
      return (
        <Segment key={patient.id}>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                Nome:
              </Grid.Column>
              <Grid.Column>
                {patient.name}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                Telefone:
              </Grid.Column>
              <Grid.Column>
                {patient.phone}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
                Email:
              </Grid.Column>
              <Grid.Column>
                {patient.email}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                Anamnesis:
              </Grid.Column>
              <Grid.Column>
                <Anamnesis patientId={patient.id} patientName={patient.name}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )
    })
  }

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Container>
        <Header as="h1">Pacientes</Header>
        {renderPatients()}
      </Container>
    </>
  );
};