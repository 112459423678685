import React from "react";
import { redirect } from "react-router-dom";

export const PrivateRoute = ({ element: Component, ...props }) => {
  if (sessionStorage.getItem("jwt")) {
    return(
      <Component {...props} />
    )    
  } else {
    throw redirect("/sign_in");
  }
}
