import axios from "axios";

let url = "";

if (window.location.hostname === "localhost") {
  url = `http://localhost:4000/api`;
} else {
  url = `https://consultiapi.gigalixirapp.com/api`;
}

export const server = () => {
  const jwt = JSON.parse(sessionStorage.getItem("jwt"))
  const auth = jwt ? { "Authorization": `Bearer ${jwt}` } : null;

  return axios.create({
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  });
};
