import {
  saveForm,
} from "../../actions/index";

export const loginService = {
  signIn: (model) => {
    const formModel = {
      email: model.username.value,
      password: model.password.value,
    };
    return saveForm("users/sign_in", formModel);
  },
};
