import React, { useEffect, useState } from "react";
import { Container, Dimmer, Grid, Header, Loader, Menu, Segment } from "semantic-ui-react";
import { AppointmentService } from "./appointment-service";
import { dateTimeAppointment, isNextAppointment } from "../../utils/date";
import { status } from "../../lib/status";
import { useSelector } from "react-redux";
import { AppointmentStatus } from "../../components/appointment-status";

export const AppointmentList = () => {
  const [loading, setLoading] = useState(true)
  const [newAppointments, setNewAppointments] = useState([])
  const [nextAppointments, setNextAppointments] = useState([])
  const [skippedAppointments, setSkippedAppointments] = useState([])
  const [appointments, setAppointments] = useState([])
  const [activeItem, setactiveItem] = useState('pendentes')
  const session = useSelector(state => state.session);

  const sortAppointments = (a, b) => {
    // Compare appointmentDate
    const dateComparison = a.appointmentDate.localeCompare(b.appointmentDate);
    if (dateComparison !== 0) {
      return dateComparison;
    }

    // Compare slot
    return a.slot.localeCompare(b.slot)
  }

  useEffect(() => {
    const load = () => {
      AppointmentService.getAppointments().then(({ data }) => {
        const pending = data.data.appointments.filter(a => a.status === "pending");
        const confirmed = data.data.appointments.filter(a => isNextAppointment(a.appointmentDate) && a.status === "confirmed")
        const skipped = data.data.appointments.filter(a => a.status === "skipped");
        setNewAppointments(pending);
        setNextAppointments(confirmed.sort(sortAppointments));
        setSkippedAppointments(skipped);
        setAppointments(data.data.appointments);
        setLoading(false)
        if (!pending.some(a => a)) {
          setactiveItem("confirmados");
        }
      });
    }
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemClick = (_e, data) => {
    setactiveItem(data.name)
  }

  const Mapper = {
    "pendentes": newAppointments,
    "confirmados": nextAppointments,
    "todos": appointments,
    "cancelados": skippedAppointments,
  }

  const Color = {
    "pending": "black",
    "skipped": "red",
    "confirmed": "green",
  }

  const handleAction = (appointment) => {
    if (appointment.status === "confirmed") {
      setNextAppointments([...nextAppointments, appointment].sort(sortAppointments));
    } else if (appointment.status === "skipped") {
      setSkippedAppointments([...skippedAppointments, appointment]);
    }
    setNewAppointments(newAppointments.filter(a => a.id !== appointment.id));
  }

  const renderStatus = (appointment) => {
    if (session.user.admin) {
      return <AppointmentStatus appointment={appointment} handleAction={handleAction} />
    }
    return <Menu stackable><Menu.Item name={status(appointment.status)} active color={Color[appointment.status]} /></Menu>
  }

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Container>
        <Menu pointing stackable>
          <Menu.Item
            name='pendentes'
            active={activeItem === 'pendentes'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name='confirmados'
            active={activeItem === 'confirmados'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name='cancelados'
            active={activeItem === 'cancelados'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name='todos'
            active={activeItem === 'todos'}
            onClick={handleItemClick}
          />
        </Menu>

        {Mapper[activeItem].map(a => {
          return (
            <Segment key={a.id}>
                <Header>{dateTimeAppointment(`${a.appointmentDate} ${a.slot}`)}</Header>
                <Grid columns={2} divided>
                  <Grid.Row>
                    <Grid.Column>
                      Local:
                    </Grid.Column>
                    <Grid.Column>
                      Online
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      Psicóloga:
                    </Grid.Column>
                    <Grid.Column>
                      {a.doctorName}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      Paciente:
                    </Grid.Column>
                    <Grid.Column>
                      {a.patientName}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      Status:
                    </Grid.Column>
                    <Grid.Column>
                      {renderStatus(a)}
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
            </Segment>
          );
        })}
      </Container>
    </>
  );
}
