import React from "react";
import { Modal } from "semantic-ui-react";

export const Content = ({content}) => {
  switch (content) {
    case "psico":
      return  (
        <>
          <Modal.Header>Porque se deve procurar ajuda psicológica no início dos problemas?</Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <p>Procurar ajuda psicológica no início dos problemas pode trazer muitos benefícios para a saúde mental e emocional de uma pessoa. Aqui estão algumas razões pelas quais é importante procurar ajuda no início dos problemas:</p>
            <p><b>1. Prevenir problemas maiores:</b> Quando problemas emocionais e psicológicos são deixados sem tratamento, eles podem piorar e levar a problemas mais graves, como transtornos de ansiedade, depressão e outros problemas de saúde mental. Procurar ajuda no início dos problemas pode ajudar a prevenir o agravamento desses problemas.</p>
            <p><b>2. Aprender habilidades de enfrentamento:</b> Procurar ajuda no início dos problemas pode ajudar a pessoa a aprender habilidades de enfrentamento saudáveis e eficazes, que podem ajudá-la a lidar com o problema atual e com outros problemas que possam surgir no futuro.</p>
            <p><b>3. Reduzir o impacto nos relacionamentos:</b> Problemas emocionais e psicológicos podem afetar os relacionamentos interpessoais, incluindo familiares, amigos e colegas de trabalho. Procurar ajuda no início dos problemas pode ajudar a reduzir o impacto negativo desses problemas nos relacionamentos.</p>
            <p><b>4. Melhorar a qualidade de vida:</b> Quando os problemas emocionais e psicológicos são abordados no início, é mais provável que a pessoa possa manter sua qualidade de vida e continuar funcionando bem no trabalho, na escola e em outros aspectos da vida.</p>
            <p><b>5. Identificar problemas subjacentes:</b> Às vezes, os problemas emocionais e psicológicos são sintomas de problemas subjacentes mais profundos. Procurar ajuda no início dos problemas pode ajudar a identificar esses problemas subjacentes e abordá-los antes que se tornem mais graves.</p>
            <p>Em resumo, procurar ajuda psicológica no início dos problemas pode ajudar a prevenir o agravamento dos problemas emocionais e psicológicos, aprender habilidades de enfrentamento saudáveis, reduzir o impacto negativo nos relacionamentos, melhorar a qualidade de vida e identificar problemas subjacentes mais cedo.</p>
            </Modal.Description>
          </Modal.Content>
        </>
      )
    case "child":
      return (
        <>
          <Modal.Header>Sintomas e sinais de alarme de problemas psicológicos na infância e na adolescência</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>Existem vários sintomas e sinais de alarme que podem indicar a presença de problemas psicológicos na infância e na adolescência. Alguns dos mais comuns incluem:</p>

              <p><b>1. Mudanças no comportamento:</b> Alterações no comportamento do filho(a) podem ser um sinal de alerta, tais como: isolamento, mudanças abruptas de humor, agressividade, birras frequentes, desobediência, falta de interesse em atividades que antes eram prazerosas, entre outros.</p>
              <p><b>2. Dificuldade de concentração e aprendizado:</b> Quando a criança ou o adolescente apresenta dificuldade em prestar atenção, memorizar informações e aprender de forma adequada, mesmo em atividades que antes eram realizadas com facilidade, pode ser um sinal de alerta.</p>
              <p><b>3. Mudanças no sono e apetite:</b> Mudanças no padrão de sono e apetite, tais como insônia, pesadelos, excesso ou falta de apetite, também podem indicar problemas psicológicos.</p>
              <p><b>4. Comportamento autolesivo:</b> Se a criança ou o adolescente começa a apresentar comportamentos autolesivos, tais como se cortar, se bater ou se queimar, isso pode ser um sinal de alerta.</p>
              <p><b>5. Abuso de substâncias:</b> O uso de substâncias químicas como drogas ou álcool pode ser um sinal de que a criança ou adolescente está tentando lidar com problemas emocionais ou psicológicos.</p>
              <p><b>6. Ansiedade e depressão:</b> Ansiedade e depressão podem afetar crianças e adolescentes e podem ser caracterizadas por uma sensação constante de tristeza, apatia, falta de energia, medo, irritabilidade e comportamento compulsivo.</p>

              <p>É importante que os pais estejam atentos a esses sinais e sintomas e, caso percebam algo de diferente, procurem ajuda profissional de um psicólogo ou outro profissional de saúde mental qualificado. O diagnóstico precoce e o tratamento adequado podem ajudar a prevenir complicações e promover a saúde mental da criança ou adolescente.</p>
            </Modal.Description>
          </Modal.Content>
        </>
      )
    case "neuro":
      return (
        <>
          <Modal.Header>Para que serve a neuropsicologia?</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>A neuropsicologia é um ramo da psicologia que se concentra no estudo das relações entre o cérebro e o comportamento humano. Ela usa técnicas de avaliação e intervenção para compreender e tratar os efeitos de lesões, doenças ou distúrbios do cérebro no comportamento e nas funções cognitivas.</p>

              <p>A neuropsicologia é uma disciplina importante em várias áreas, incluindo a saúde, a educação e a pesquisa. Alguns dos principais usos da neuropsicologia são:</p>

              <p><b>1. Avaliação neuropsicológica:</b> A avaliação neuropsicológica é usada para avaliar as funções cognitivas de um indivíduo, incluindo a atenção, a memória, o raciocínio e a linguagem. Essa avaliação pode ajudar a identificar deficiências ou distúrbios cognitivos causados por lesões cerebrais, doenças neurodegenerativas ou outros problemas de saúde.</p>
              <p><b>2. Reabilitação neuropsicológica:</b> A reabilitação neuropsicológica é usada para ajudar as pessoas a recuperar funções cognitivas que foram afetadas por lesões ou doenças do cérebro. Essa abordagem envolve o treinamento de habilidades cognitivas específicas para ajudar o indivíduo a recuperar a função e a autonomia em suas atividades cotidianas.</p>
              <p><b>3. Tratamento de distúrbios psicológicos:</b> A neuropsicologia também pode ser usada para tratar distúrbios psicológicos que têm uma base biológica, como a esquizofrenia, o transtorno bipolar e a depressão. O tratamento pode incluir o uso de técnicas de neuromodulação, como a estimulação magnética transcraniana, para modificar a atividade do cérebro e melhorar os sintomas.</p>
              <p>Pesquisa em neurociência: A neuropsicologia é usada em pesquisas para compreender as bases neurobiológicas de comportamentos e funções cognitivas. Ela ajuda os pesquisadores a compreender como o cérebro funciona em diferentes contextos, o que pode levar a novas terapias e tratamentos para distúrbios neurológicos e psicológicos.</p>
            </Modal.Description>
          </Modal.Content>
        </>
      )
    default:
      return null
  }
}