import { useState } from "react";
import { Button, Menu, Modal } from "semantic-ui-react";
import { AppointmentService } from "../views/appointment/appointment-service";
import { dateFormatter } from "../utils/date";

export const AppointmentStatus = (props) => {
  const [openState, setOpen] = useState(false);
  const [appointment, setAppointment] = useState(props.appointment);
  const [selectedStatus, setselectedStatus] = useState(props.appointment.status);

  const open = (_e, data) => {
    if (selectedStatus !== data.name) {
      setselectedStatus(data.name);
      setOpen(true);
    }
  };

  const cancel = () => {
    setselectedStatus(appointment.status);
    setOpen(false);
  };

  const confirm = () => {
    AppointmentService.updateStatus(appointment.id, selectedStatus)
      .then(({data}) => {
        const updatedAppointment = {...appointment, status: data.data.status};
        setAppointment(updatedAppointment);
        props.handleAction(updatedAppointment);
      })
      .finally(() => setOpen(false));
  };

  const appointmentConfirmationEmail = () => {
    if(selectedStatus === 'confirmed') {
      return (
        <div>
          <p>Prezado(a) {appointment.patientName},</p>
  
          <p>Este é um e-mail para confirmar a sua consulta agendada com o(a) Dr(a). {appointment.doctorName}.</p>
          <p>Detalhes da consulta:</p>
          <p>Data: {dateFormatter(appointment.appointmentDate)}</p>
          <p>Horário: {appointment.slot}</p>
          <p>Local: Online</p>
  
          <p>Em caso de qualquer dúvida ou se precisar reagendar, entre em contato conosco.</p>
  
          <p>Atenciosamente,</p>
          <p>Equipe {appointment.doctorName}</p>
        </div>
      );
    } else if (selectedStatus === 'skipped') {
      return (
        <div>
          <p>Prezado(a) {appointment.patientName},</p>
          <p>Infelizmente não foi possível agendar a consulta com o(a) Dr(a). {appointment.doctorName}.</p>
          <p>Detalhes da consulta:</p>
          <p>Data: {dateFormatter(appointment.appointmentDate)}</p>
          <p>Horário: {appointment.slot}</p>
          <p>Local: Online</p>
  
          <p>Para mais detalhes, por favor, entre em contato conosco.</p>
  
          <p>Atenciosamente,</p>
          <p>Equipe {appointment.doctorName}</p>
        </div>
      )
    }
  };

  return(
    <div>
      <Modal
        onClose={cancel}
        onOpen={confirm}
        open={openState}
      >
        <Modal.Header>Confirmação de consulta</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Email para: {appointment.patientEmail}</p>
            {appointmentConfirmationEmail()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={cancel}>
            Cancelar
          </Button>
          <Button
            content="Confirmar"
            labelPosition='right'
            icon='checkmark'
            onClick={cancel}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Menu stackable>
        <Menu.Item
          name='pending'
          active={appointment.status === 'pending'}
          content='Pendente'
        />
        <Menu.Item
          name='confirmed'
          active={appointment.status === 'confirmed'}
          content='Confirmado'
          onClick={open}
          color="green"
        />
        <Menu.Item
          name='skipped'
          active={appointment.status === 'skipped'}
          content='Cancelado'
          onClick={open}
          color="red"
        />
      </Menu>
    </div>
  );
}
